<script lang="ts" setup>
  import type {
    AspectRatioPair,
    VideoMimeType,
    VideoFileExtension,
    MediaRatios,
    ImageProviderSource,
    ImgLoading
  } from '@/types/media'

  const props = withDefaults(
    defineProps<{
      src: string
      alt?: string
      loading?: ImgLoading
      preload?: boolean
      provider?: ImageProviderSource
      ratio?: string | undefined
      ratios?: MediaRatios<string> | undefined
    }>(),
    {
      alt: '',
      loading: 'lazy',
      preload: false,
      provider: 'contentful',
      ratio: undefined,
      ratios: undefined
    }
  )

  const aspectRatio = computed<AspectRatioPair | undefined>(() => {
    if (!props.ratios || !props.ratio) {
      return undefined
    }
    return props.ratios[props.ratio]
  })

  const fileExtMimeMap: Record<VideoFileExtension, VideoMimeType> = {
    '.webm': 'video/webm',
    '.mp4': 'video/mp4'
  }

  const videoFileExts: VideoFileExtension[] = ['.webm', '.mp4']

  const fileExt = computed<VideoFileExtension>(() => {
    const arr = props.src.split('.')
    return ('.' + arr[arr.length - 1]) as VideoFileExtension
  })

  const isVideo = computed<boolean>(() => videoFileExts.includes(fileExt.value))
</script>

<template>
  <div
    :style="{
      '--aspect-ratio-desktop': !!aspectRatio
        ? `${aspectRatio.desktop.numerator} / ${aspectRatio.desktop.denominator}`
        : undefined,
      '--aspect-ratio-mobile': !!aspectRatio
        ? `${aspectRatio.mobile.numerator} / ${aspectRatio.mobile.denominator}`
        : undefined
    }"
    class="c-image-block"
  >
    <CVideo v-if="isVideo" :loading :ratio :ratios :src :type="fileExtMimeMap[fileExt]" class="graphic" loop muted />

    <NuxtImg
      v-else
      :alt
      :loading="preload ? 'eager' : loading"
      :placeholder="provider === 'contentful' ? src + '?fm=webp&w=768&q=50' : src"
      :preload
      :provider
      :src="provider === 'contentful' ? src + '?fm=webp' : src"
      class="graphic"
      sizes="1400px xs:640px sm:768px md:992px lg:1200px xl:1400px"
    />
  </div>
</template>

<style lang="scss" scoped>
  .c-image-block {
    width: 100%;

    background-color: inherit;

    .graphic {
      object-fit: cover;

      aspect-ratio: var(--aspect-ratio-mobile);

      @include screenMdAndUp {
        aspect-ratio: var(--aspect-ratio-desktop);
      }

      width: 100%;
      height: 100%;
      max-width: var(--breakpoint-xl);

      margin-left: auto;
      margin-right: auto;
    }
  }
</style>
